<template>
  <div class="container_left">
    <div class="element" v-for="(item,index) in leftData" :key="index">
      <p>{{item.name}}</p>
      <div class="tag-box">
        <p class="para-line" :class="element.checked ? 'flex_a checked close' : 'flex_a'" v-for="(element,idx) in item.newArrays" :key="idx" @click="childClick(index,idx)">
          <el-tooltip class="item" effect="dark" :content="element.name" placement="bottom">
            <span class="line1">{{element.name}} </span>
          </el-tooltip> 
          <span class="result">（{{element.result || element.value}}）</span>
          <i v-if="element.checked" class="iconfont icon-shanchu close-icon" @click.stop="del(element.ruid,element.name,index,idx)"></i>
        </p>
        <div class="setting" v-if="item.type == 1 || item.type == 2 || item.type == 3">
          <i @click="loadingMore(index)" :class="(item.type == 1 || item.type == 2) ? 'iconfont icon-icon_arrow_bottom checked' : 'iconfont icon-icon_arrow_bottom'"></i>
          <i @click="spliceMore(index)" :class="(item.type == 2 || item.type == 3) ? 'iconfont icon-icon_arrow_top checked' : 'iconfont icon-icon_arrow_top'"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftMenu",
  props:{
    left:{
      type: Array,
      default: () => {
        return []
      }
    },
    filter: Array,
    delTag: Object,
    child: Object,
    defaultPublisher:{
      type: String,
      defaule: 1,
    },
    defaultSeries:{
      type: String,
      defaule: 1,
    }
  },
  watch:{
    'child': {
      handler(val) {
        if (val) {
          this.childValue.push(val)
        }
      },
      deep: true
    },
    left(val){ 
      val.forEach((element) => {
        element.nums = []
        element.items.forEach((item) => {
          // 筛选出数量不为0 的
            if(item.result != 0){ 
              element.nums.push(item)
            }
        });
      });
      this.leftData = val
      this.leftData.forEach((element,index) => {
          element.nums.forEach((item,idx) => {
            if(!item.ruid){
              element.nums.splice(idx,1)
            }
            this.childValue.forEach(childItem => {
              if(childItem.ruid == item.ruid){
                this.leftData[index].nums = []
                this.leftData[index].nums.push(item)
              }
            });
          });
        });
      this.getData()
    },
    // defaultPublisher(val) {
    //   this.getData()
    //   console.log(val+'+++++');
    // },
    delTag(val) {
      console.log('-----------')
      const target = this.childValue.find(item => (item.ruid === val.ruid && item.name && val.name))
      console.log(target)
      if (target) this.del(val.ruid, val.name, val.index, val.idx)
    }
  },
  data() {
    return {
      curIndex: [], //存储点击的聚合
      leftData: [],//数据初始化
      childValue: [],//存储要传到父组件的聚合集合
      loadMore: false,
      loaded: false,
      filterTags: [],
      childArr: [],
      checkFlag: 0,
      checkFlag_s: 0,
    };
  },
  mounted() {
  },
  methods: {
    findTag(tags) {
      let oldTags = this.filterTags
      oldTags.forEach((oldTag, index) => {
        let idx = tags.findIndex(tag => tag.id === oldTag.id)
        if (idx > -1) this.deleteTag(tags[idx], index)
      })
    },
    deleteTag(tag, index) {
      let tags = this.filterTags
      tags.splice(index, 1)
      this.filterTags = tags
      let data = this.leftData
      data.forEach((item) => {
        const items = item.items
        let n = tag.name
        const idx = items.findIndex(elem => (n === elem.name && tag.id === elem.ruid))
        if (idx != -1) {
          return this.doDel(tag.id, tag.name, tag.index, tag.idx)
        }
      })
    },
    getData() {
      this.leftData.forEach(element => {
        element.newArrays = []
        element.nums.forEach((item,index) => {
          if(element.name.indexOf('学科') != -1){ //学科类目下  接口url不需要拼链接
            this.$set(item,'subject',true)
          }
          if(index < 3){
            element.newArrays.push(item)
          }
        });
        if(element.nums.length > 3){
          element.type = 1
        }
      });
      if(this.defaultPublisher && this.checkFlag == 0){
        if(this.leftData.length > 0){
          this.leftData[10].nums.forEach((element,idx) => {
            if(element.name == this.defaultPublisher){
              this.checkFlag++
              this.childClick(10,idx, true)
            }
            
          });
        }
        
      }
      console.log(this.defaultSeries);
      if(this.defaultSeries && this.checkFlag_s == 0){
        if(this.leftData.length > 0){
          this.leftData[11].nums.forEach((element,idx) => {
            if(element.name == this.defaultSeries){
              this.checkFlag_s++
              this.childClick(11,idx, true)
            }
            
          });
        }
      }
      this.curIndex.forEach(element => {
        if(this.leftData.length > 0){
          this.leftData[element].newArrays[0].checked = true
        }
      });
      const filter = this.filter
      if (!filter || !filter.length) return false
      this.handleFilter(filter, this.leftData)
    },
    handleFilter(arr, left) {
      if (this.loaded) return false
      this.loaded = true
      let filters = []
      arr.forEach((item) => {
          console.log(item)
        let parentIndex, childIndex
        left.forEach((elem, idx) => {
          if (elem.name === item.name) {
            parentIndex = idx
          }
        })
        if (!parentIndex && parentIndex != 0) return

        const items = left[parentIndex].items
        let dup = false
        items.forEach((child, index) => {
          if (dup) return
          if (item.item.name == child.name) {
            childIndex = index
            dup = true
          }
          {
            // let field = child.filterfield
            // let target = field
            // let id = child.ruid
            // if (Array.isArray(field)) {
            //   id = field[0].searchWord
            //   target = field[0].fieldName
            // }
            // if (dup) return
            // if (item.item.fieldname == target && item.item.searchWord == id) {
          }
        })
        console.log(parentIndex, childIndex)
        filters.push({ parentIndex, childIndex })
      })
      filters.forEach((obj, idx) => {
        this.childClick(obj.parentIndex, obj.childIndex, false)
        if (idx == (filters.length - 1)) {
          this.$emit('filter-left', this.childArr)
        }
      })
    },
    // 加载更多
    loadingMore(index) {
      let nowNum = this.leftData[index].newArrays.length + 5
      this.leftData[index].newArrays = this.leftData[index].nums.slice(0,nowNum)
      this.leftData[index].type = 2
      if(this.leftData[index].newArrays.length == this.leftData[index].nums.length){
        this.leftData[index].type = 3
      }
      this.$forceUpdate()
    
    },
    // 收起 更多
    spliceMore(index) {

      let nowNum = this.leftData[index].newArrays.length - 5
      if(nowNum <= 3){
        nowNum = 3
      }
      this.leftData[index].newArrays = this.leftData[index].nums.slice(0, nowNum)

      if(this.leftData[index].newArrays.length == 3){
        this.leftData[index].type =  1
      }
      this.$forceUpdate()
    },
    // 点击根据筛选条件查询
    childClick(index,idx,emit=true) {
      let obj = {
        title: this.leftData[index].name,
        name: this.leftData[index].nums[idx].name,
        ruid: this.leftData[index].nums[idx].ruid,
        index,
        idx
      }
      this.filterTags.push(obj)

      this.curIndex.push(index)
      this.curIndex = [...new Set(this.curIndex)]
      console.log(this.curIndex);

      // 防止重复
      this.childValue.push(this.leftData[index].nums[idx])
      for (var i = 0; i < this.childValue.length; i++) {
        for (var j = i + 1; j < this.childValue.length; j++) {
          if ((this.childValue[i].ruid === this.childValue[j].ruid) && (this.childValue[i].name === this.childValue[j].name)) {
            this.childValue.splice(j, 1);
            j = j - 1;
          }
        }
      }

      const arr = this.childValue.concat(this.filterTags)
      console.log(arr);

      if (emit) this.$emit('childByValue', arr)
      else {
        this.childArr = arr
      }
    },
    // 删除具体条件
    del(ruid, name, arrayIndex) {
      let tags = this.filterTags.filter(item => {
        return (item.id != ruid && item.name != name)
      })
      this.filterTags = tags

      this.childValue.forEach((element,index) => {
        if(ruid == element.ruid && name == element.name){
          this.childValue.splice(index, 1);
        }
      });
      this.curIndex.forEach((element,index) => {
        if(element == (this.delIndex > -1 ? this.delIndex : arrayIndex)){
            this.curIndex.splice(index, 1);
        }
      });

      const arr = this.childValue.concat(this.filterTags)
      
      this.$emit('childByValue', arr)
      this.checkFlag = 0
      this.checkFlag_s = 0
      this.defaultPublisher = ''
      this.defaultSeries = ''
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.container_left {
  background: #FFF;
  padding: 20px;
  .element{
    background: #FFF;
    margin-bottom: 10px;
    padding-bottom: 0;
    &:last-of-type{
      margin: 0;
    }
    .tag-box {
      background-color: #eee;
      padding: 10px;
    }
    >p{
      // border-bottom: 1px solid #E6E6E6;
      font-weight: bold;
      font-size: 17px;
      color: #333;
      padding-bottom: 15px;
    }
    >div{
      padding-top: 15px;
      p{
        margin-bottom: 15px;
        cursor: pointer;
        &:hover{
          span{
            &:nth-of-type(1){
              color: $mainColor;
            }
          }
        }
        &:last-of-type{
          margin: 0;
        }
        span{
          height: 22px;
          &:nth-of-type(1){
            display: inline-block;
            max-width: 75%;
          }
          &:nth-of-type(2){
            color: #666;
          }
        }
        i{
          margin-left: auto;
          color: $mainColor;
        }
      }
      .checked{
        color: $mainColor;
      }
      .close {
        display: flex;
        align-items: center;
      }
      .setting{
        text-align: center;
        margin-top: 10px;
        i{
          margin: 0 10px;
          cursor: pointer;
          color: #999999;
        }
        .checked{
          color: $mainColor;
        }
      }
    }
  }
}
.close-icon {
  font-size: 1.5rem;
}
.line1{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.line2{
  overflow:hidden; 
  text-overflow:ellipsis;
  -webkit-line-clamp:2; 
  /* -webkit-box-orient: vertical; */
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display:-webkit-box; 
  word-break: break-all;
}
.flex_a{
  display: flex;
  align-items: center;
}
.para-line {
  display: flex;
  align-items: start;
  i {
    align-self: center;
  }
}
.result {
  color: #999;
}
</style>
