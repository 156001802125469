<template>
  <div class="search-container">
    <el-select v-model="selectVal" class="search-select" v-if="haveOption">
      <el-option
        v-for="item in options"
        :key="item.value"
        :value="item.value"
        :label="item.label"
      />
    </el-select>
    <div>
      <el-input
        v-model="searchVal"
        class="search-input"
        :class="{ 'have-option': haveOption }"
        @keyup.enter.native="doSearch"
        placeholder="请输入检索内容"
        clearable
      >
        <template slot="append">
          <el-button
            type="primary"
            @click="doSearch"
            class="search-button"
            icon="el-icon-search"
          />
        </template>
      </el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    haveOption: {
      type: Boolean,
      default: true,
    },
    options: Array,
    refreshKey: Number,
  },
  watch: {
    options(val) {
      if (!val.length) return
      this.selectVal = val[0].value
    },
    refreshKey() {
      this.searchVal = ''
      this.selectVal = this.options[0].value
    },
  },
  data() {
    return {
      selectVal: '',
      searchVal: '',
    }
  },
  methods: {
    doSearch() {
      if (this.searchVal.substring(0, 3) === '978') {
        this.searchVal = this.lodash.replace(this.searchVal, /-/g, '')
      }
      const val = this.searchVal.trim()
      let obj = { option: this.selectVal, value: val }
      this.$emit('do-search', obj)
    },
  },
  mounted() {
    // this.selectVal = this.options[0].value
  },
}
</script>

<style lang="scss" scoped>
.search-container {
  display: flex;
  position: relative;
  .search-select {
    max-width: 120px;
    display: inline-block;
    border-radius: 0;
    /deep/ input:hover {
      border: 1px solid #dcdfe6;
      border-right: none;
    }
    /deep/ input:focus {
      border: 1px solid #dcdfe6;
      border-right: none;
    }
    /deep/ .el-input__inner {
      border-radius: 0;
      border-right: 0;
      position: relative;
      height: 42px;
    }
    /deep/ .el-input__suffix:after {
      content: '|';
    }
    /deep/ .el-input__icon {
      line-height: 35px;
    }
  }
  .search-input {
    width: 270px;
    border-radius: 0;
    /deep/ input:hover {
      border: 1px solid #dcdfe6;
    }
    /deep/ input:focus {
      border: 1px solid #dcdfe6;
    }
    /deep/ .el-input__inner {
      border-radius: 0;
      height: 42px;
    }
    .search-button {
      background-color: $mainColor;
      color: #fff;
      border: 1px solid $mainColor;
      border-radius: 0;
    }
  }
  .have-option {
    & /deep/ .el-input__inner {
      border-left: none;
    }
    /deep/ input:hover {
      border-left: none;
    }
    /deep/ input:focus {
      border-left: none;
    }
  }
  /deep/ .el-scrollbar__wrap {
    overflow: hidden;
  }
}
</style>
