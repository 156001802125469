<template>
  <div class="buttons">
    <el-button
      v-for="(item, index) in buttons"
      :key="item.text"
      class="sort-buttons"
      :class="{ 'active-button': index === activeBtn }"
      @click="changeSort(index)"
    >
      {{ item.text }}
      <i v-if="item.text !== '综合'" :class="arrowType(index)" />
    </el-button>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    sortFilters: Array,
    sortKey: Number,
  },
  data() {
    return {
      activeBtn: 0,
      sortMethod: 'D',
      once: true,
      buttons: [],
    }
  },
  computed: {
    firstValue() {
      return `orderfield1=10400001&ordertype1=${this.sortMethod}&orderfield2=10100001&ordertype2=${this.sortMethod}&orderfield3=10120001&ordertype3=${this.sortMethod}&orderfield4=10500011&ordertype4=${this.sortMethod}`
    },
  },
  mounted() {
    this.buttons = this.sortFilters.map((item) => {
      item.up = false
      return item
    })
  },
  watch: {
    sortFilters(val) {
      if (!this.once) return false
      this.buttons = val.map((item) => {
        if (item.text == '综合') {
          item.value =
            'orderfield1=10400001&ordertype1=D&orderfield2=10100001&ordertype2=D&orderfield3=10120001&ordertype3=D&orderfield4=10500011&ordertype4=D'
        }
        item.up = false
        return item
      })
      this.once = false
    },
    sortKey(val) {
      if (val && this.buttons.length) {
        const idx = this.buttons.findIndex((item) => item.value == '10500011')
        this.changeSort(idx)
      }
    },
  },
  methods: {
    changeSort(index) {
      let target = this.buttons[index]

      let myButtons = this.buttons
      this.buttons = myButtons.map((item) => {
        if (item.ruid === target.ruid) return item
        item.up = false
        return item
      })

      if (target.text == '相关度') {
        this.sortMethod = ''
      } else if (this.activeBtn === index) {
        target.up = !target.up
        this.sortMethod = this.sortMethod === 'A' ? 'D' : 'A'
      } else {
        this.sortMethod = 'D'
      }
      this.activeBtn = index

      let orderObj = {
        ordertype: this.sortMethod,
        orderfield: target.value,
      }
      if (target.text === '综合') {
        orderObj.ordertype = ''
        orderObj.orderfield = ''
      }
      this.$emit('change-sort', orderObj)
    },
    arrowType(index) {
      const upBtn = 'iconfont icon-xiangshangjiantoucuxiao'
      const downBtn = 'iconfont icon-icon_xiangxiajiantoucuxiao'
      return this.buttons[index].up ? upBtn : downBtn
    },
  },
}
</script>

<style lang="scss" scoped>
.buttons {
  box-sizing: border-box;
  .sort-buttons {
    margin: 0;
    height: 42px;
    border-radius: unset;
    border-right: none;
    &:last-child {
      border-right: 1px solid #dcdfe6;
    }
  }
  .active-button {
    background-color: $mainColor;
    border-color: $mainColor;
    color: #fff;
  }
  .hidden {
    i {
      visibility: hidden;
    }
  }
}
</style>
