<template>
  <el-pagination
    class="list-pager"
    :current-page="page.pageno + 1"
    :page-size="page.pagesize"
    :total="totalCount"
    @current-change="changePage"
    layout="prev, pager, next, jumper"
    background
    hide-on-single-page
  />
</template>

<script>
export default {
  name: '',
  props: {
    page: {
      type: Object,
      default() {
        return {
          pageno: 0,
          pagesize: 10,
          total: 0
        }
      }
    }
  },
  computed: {
    totalCount() {
      const total = this.page.total
      return (total > 10000 ? 10000 : total)
    }
  },
  methods: {
    changePage(page) {
      this.$emit('change-page', (page - 1))
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-pager {
    margin-top: 30px;
    text-align: center;
  }
</style>
